.kmp6lq_textCenter {
  text-align: center !important;
}

.kmp6lq_textLeft {
  text-align: left !important;
}

.kmp6lq_textRight {
  text-align: right !important;
}

.kmp6lq_linkHighlight {
  color: #f0f0f0;
  transition: color .125s;
}

.kmp6lq_linkHighlight:hover {
  color: #b0b0b0;
  transition: color .125s;
}

.kmp6lq_listUnstyled {
  list-style: none;
}

.kmp6lq_flexColumn {
  flex-direction: column;
  display: flex;
}

.kmp6lq_flexRow {
  flex-direction: row;
  display: flex;
}

/*# sourceMappingURL=stroke.4162205a.css.map */
